export const Split_TCG_L_B = [
    {
        heading: "Why might I have a claim?",
        content: "<p>If you purchased a vehicle on finance between 2007 and 2021, then it is likely that the car finance was mis-sold to you due to the car dealership failing to disclose commission they were paid by the lender for setting up your car finance. Unbeknown to consumers, some lenders allowed car dealerships to increase the interest rate paid by consumers for their car finance, to increase the commission they earned. It is estimated that consumers were overcharged by a staggering £300 million a year for their car finance due to the poor practices of car dealerships and lenders*. *See: <a target='_blank' href='https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf s. 2.17, Pg9.'>https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf s.2.17,Pg9.</a></p>"
    },
    {
        heading: "How long will my claim take?",
        content: "<p>Lenders are usually expected to provide a response to any complaint received within 8 weeks, but the Financial Conduct Authority (FCA) has temporarily paused this timescale for car finance complaints about Discretionary Commission Arrangements, allowing lenders additional time to provide a response to due to investigative work in this area and the volumes of claims lenders are receiving. At present, we expect that lenders will start responding to complaints about Discretionary Commission Arrangements after 25 September 2024.<br>If your complaint is escalated to the Financial Ombudsman Service (FOS), it can take four to six months to receive an initial response and may take longer if your case is about a Discretionary Commission Arrangement or is referred to an Ombudsman. We are continuously working with lenders and the FOS to ensure that timely and fair responses are provided to your claims.</p>"
    },
    {
        heading: "How much could my claim be worth?",
        content: 'The amount of compensation you may be entitled to is dependent on several factors, including the amount of finance your lender provided, the date the finance was taken out and the duration of the agreement. The Financial Conduct Authority, confirmed in their publication Our work on motor finance – final finding – March 2019 that on a typical motor finance agreement of £10,000, a Discretionary Commission Arrangement typically resulted in consumers being overcharged £1,100 over a four-year agreement.* *See: <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf" target="_blank">https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf</a> s. 2.15, Pg9.</p>'
    },
    {
        heading: "Do I need to use a law firm, or claims management company to make my claim?",
        content: '<p>You do not have to use a law firm, or claims management company to make your claim. You can claim directly yourself for free to your lender, and then the Financial Ombudsman. Although you can make your claim directly, you may find it preferrable to use a professional representative, like us, to help you manage the claims process due to our expertise in this area, or simply because you do not have the time.</p>'
    },
    {
        heading: "What are your fees?",
        content: '<p>If your claim is successful, our Success Fee is 48%, inclusive of VAT, of any compensation that your lender offers to you. An Administration Charge of £10.00 also applies to all claims. If you have an outstanding balance with your lender they may use all, or part of, your compensation to pay this outstanding balance meaning that you may not receive any cash “in hand”. In this event, our Success Fee will still be payable against the full compensation amount.</p>'
    },
    {
        heading: "I was referred to you via a third-party, how are they paid?",
        content: "<p>If you have been introduced to us via a third-party, we will pay them a fee for this introduction. This fee is usually a percentage of our Success Fee for any successful claim. This fee is paid directly from us to the third-party at no cost to you.</p>"
    }
];


export const Split_TCG_L_A = [
    {
        heading: "Why might I have a claim?",
        content: "<p>If you purchased a vehicle on finance between 2007 and 2021, then it is likely that the car finance was mis-sold to you due to the car dealership failing to disclose commission they were paid by the lender for setting up your car finance. Unbeknown to consumers, some lenders allowed car dealerships to increase the interest rate paid by consumers for their car finance, to increase the commission they earned. It is estimated that consumers were overcharged by a staggering £300 million a year for their car finance due to the poor practices of car dealerships and lenders*. *See: <a target='_blank' href='https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf s. 2.17, Pg9.'>https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf s.2.17,Pg9.</a></p>"
    },
    {
        heading: "How long will my claim take?",
        content: "<p>Lenders are usually expected to provide a response to any complaint received within 8 weeks, but the Financial Conduct Authority (FCA) has temporarily paused this timescale for car finance complaints about Discretionary Commission Arrangements, allowing lenders additional time to provide a response to due to investigative work in this area and the volumes of claims lenders are receiving. At present, we expect that lenders will start responding to complaints about Discretionary Commission Arrangements after 25 September 2024.<br>If your complaint is escalated to the Financial Ombudsman Service (FOS), it can take four to six months to receive an initial response and may take longer if your case is about a Discretionary Commission Arrangement or is referred to an Ombudsman. We are continuously working with lenders and the FOS to ensure that timely and fair responses are provided to your claims.</p>"
    },
    {
        heading: "How much could my claim be worth?",
        content: 'The amount of compensation you may be entitled to is dependent on several factors, including the amount of finance your lender provided, the date the finance was taken out and the duration of the agreement. The Financial Conduct Authority, confirmed in their publication Our work on motor finance – final finding – March 2019 that on a typical motor finance agreement of £10,000, a Discretionary Commission Arrangement typically resulted in consumers being overcharged £1,100 over a four-year agreement.* *See: <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf" target="_blank">https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf</a> s. 2.15, Pg9.</p>'
    },
    {
        heading: "Do I need to use a law firm, or claims management company to make my claim?",
        content: '<p>You do not have to use a law firm, or claims management company to make your claim. You can claim directly yourself for free to your lender, and then the Financial Ombudsman. Although you can make your claim directly, you may find it preferrable to use a professional representative, like us, to help you manage the claims process due to our expertise in this area, or simply because you do not have the time.</p>'
    },
    {
        heading: "What are your fees?",
        content: '<p>If your claim is successful, our Success Fee is 48%, inclusive of VAT, of any compensation that your lender offers to you. An Administration Charge of £10.00 also applies to all claims. If you have an outstanding balance with your lender they may use all, or part of, your compensation to pay this outstanding balance meaning that you may not receive any cash “in hand”. In this event, our Success Fee will still be payable against the full compensation amount.</p>'
    },
    {
        heading: "I was referred to you via a third-party, how are they paid?",
        content: "<p>If you have been introduced to us via a third-party, we will pay them a fee for this introduction. This fee is usually a percentage of our Success Fee for any successful claim. This fee is paid directly from us to the third-party at no cost to you.</p>"
    }
];


export const TCG_L_D = [
    {
        heading: " Why might I have a claim?",
        content: (<> If you purchased a vehicle on finance between 2007 and 2021, then
            it is likely that the car finance was mis-sold to you due to the
            car dealership failing to disclose commission they were paid by
            the lender for setting up your car finance. Unbeknown to
            consumers, some lenders allowed car dealerships to increase the
            interest rate paid by consumers for their car finance, to increase
            the commission they earned. It is estimated that consumers were
            overcharged by a staggering £300 million a year for their car
            finance due to the poor practices of car dealerships and lenders*.
            <a
                target="_blank"
                href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf"
            >
                *See:
                https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf
                s.2.17,Pg9.
            </a> </>)
    },
    {
        heading: "How long will my claim take?",
        content: <> Lenders are usually expected to provide a response to any
            complaint received within 8 weeks, but the Financial Conduct
            Authority (FCA) has temporarily paused this timescale for car
            finance complaints about Discretionary Commission Arrangements,
            allowing lenders additional time to provide a response to due to
            investigative work in this area and the volumes of claims lenders
            are receiving. At present, we expect that lenders will start
            responding to complaints about Discretionary Commission
            Arrangements after 25 September 2024. If your complaint is
            escalated to the Financial Ombudsman Service (FOS), it can take
            four to six months to receive an initial response and may take
            longer if your case is about a Discretionary Commission
            Arrangement or is referred to an Ombudsman. We are continuously
            working with lenders and the FOS to ensure that timely and fair
            responses are provided to your claims.</>
    },
    {
        heading: " How much could my claim be worth?",
        content: <>The amount of compensation you may be entitled to is dependent on
            several factors, including the amount of finance your lender
            provided, the date the finance was taken out and the duration of
            the agreement. The Financial Conduct Authority, confirmed in their
            publication Our work on motor finance – final finding – March 2019
            that on a typical motor finance agreement of £10,000, a
            Discretionary Commission Arrangement typically resulted in
            consumers being overcharged £1,100 over a four-year agreement.*
            *See:{" "}
            <a
                target="_blank"
                href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf"
            >
                https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-finalfindings.pdf
            </a>
            s. 2.15, Pg9.</>
    },
    {
        heading: " Do I need to use a law firm, or claims management company to make my claim?",
        content: <>  You do not have to use a law firm, or claims management company to
            make your claim. You can claim directly yourself for free to your
            lender, and then the Financial Ombudsman. Although you can make
            your claim directly, you may find it preferrable to use a
            professional representative, like us, to help you manage the
            claims process due to our expertise in this area, or simply
            because you do not have the time.</>
    },
    {
        heading: "What are your fees?",
        content: <>
        <p>
          If we are successful in obtaining an offer or payment of Compensation, then you pay us a
          Success Fee which is calculated based on the amount of Compensation your lender offers
          per credit agreement. The table below details the Success Fee percentage applicable to
          different bands of Compensation, and the maximum total Success Fee for each compensation
          band.
        </p>
        <table className='table table-striped table-bordered'>
          <thead>
            <tr>
              <th scope='col'>Compensation</th>
              <th scope='col'>Success Fee Percentage</th>
              <th scope='col'>Maximum Fee Charge</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>£1 - £1,499</td>
              <td>30%</td>
              <td>£420</td>
            </tr>
            <tr>
              <td>£1,500 - £9,999</td>
              <td>28%</td>
              <td>£2,500</td>
            </tr>
            <tr>
              <td>£10,000 - £24,999</td>
              <td>25%</td>
              <td>£5,000</td>
            </tr>
            <tr>
              <td>£25,000 - £49,999</td>
              <td>20%</td>
              <td>£7,500</td>
            </tr>
            <tr>
              <td>£50,000, or more</td>
              <td>15%</td>
              <td>£10,000</td>
            </tr>
          </tbody>
        </table>

        <p>
          The percentages and amounts in the table above are exclusive of VAT, which is is charged
          at the prevailing rate.
        </p>
        <p>
          If you have an outstanding balance with your Lender they may use all, or part of, your
          compensation to pay this outstanding balance meaning that you may not receive any cash
          “in hand”. In this event, our Success Fee will still be calculated against the full
          compensation amount.
        </p>
        <p>
          Example 1: If your Lender makes an offer of £1,000 compensation and you have no
          outstanding balance with the Lender, your Lender will pay us £1,000. Of this, £360 will
          be deducted to pay our Success Fee and VAT, and £640 will be paid to you.
        </p>
        <p>
          Example 2: If your Lender makes an offer of £1,000 compensation and you have an
          outstanding balance of £800 with the Lender, your Lender may use the compensation
          offered to pay the outstanding balance meaning they will only pay us £200. We would use
          this £200 toward our Success Fee and VAT, which would leave a balance of £160 payable by
          you.
        </p>
      </>
    },
    {
        heading: " I was referred to you via a third-party, how are they paid?",
        content: <> If you have been introduced to us via a third-party, we will pay
            them a fee for this introduction. This fee is usually a percentage
            of our Success Fee for any successful claim. This fee is paid
            directly from us to the third-party at no cost to you.</>
    },
]