import React, { useContext, useState, useRef, useEffect } from "react";
import "../../../assets/css/TCG_L_A/idupload.scss";
import "../../../assets/css/TCG_L_A/custom.scss";
import uploadgif from "../../../assets/img/upload.gif";
import Button from "../../UI/Button";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import Header from "../../Includes/Layouts/TCG_L_C/Header";
import Footer from "../../Includes/Layouts/TCG_L_C/Footer";
import tcgl_logo from "../../../assets/img/tcgl-logo.png";
import car_insurance from "../../../assets/img/car-insurance.png";
import plane from "../../../assets/img/plane.png";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import Loader from "../../Includes/Layouts/TCG_L_C/Loader";
import { useSkipHandling } from "../../../Hooks/useSkipHandling";
import PreviousSurnameModel from "../../Includes/Layouts/TCG_L_C/PreviousSurnameModel";
import PreviousAddressModel from "../../Includes/Layouts/TCG_L_C/PreviousAddressModel";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
const IdUploadV5_C4 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_C_4",
    "v5_C4/id-upload"
  );
  const [showSlide2, setShowSlide2] = useState(false);
  const [idType, setIdType] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [showLoader, setShowLoader] = useState("show");
  const [progressbar, setProgressBar] = useState(false);
  const [userfile, setUserfile] = useState({ preview: "", title: "" });
  const [selectedFile, setSelectedFile] = useState("");
  const { saveDataIngestion } = useDataIngestion();
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedFileType, setSelectedFileType] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveIdUpload, isLoading } = useIdUpload();
  const { handleSkip } = useSkipHandling();
  const [previousSurnameModelShow, setPreviousSurnameModelShow] = useState(false);
  const [previousAddressModelShow, setPreviousAddressModelShow] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const split_name = query.get("splitName");

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };

  const uuid = getUuid();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const timeout = setTimeout(() => {
      setShowLoader("hide");
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  const handleNextUploadClick = (e) => {
    setIdType(e.target.id);
    setShowSlide2(true);
  };

  const handleBackbutton = () => {
    setShowSlide2(false);
    setIsFileSelected(false);
    setSelectedFileType("");
    setUserfile({ preview: "", title: "" });
    setBase64Image("");
    setErrorMessage("");
  };

  const handleButtonClick = (e) => {
    // e.preventDefault();
    if (e.target.name == "photo") {
      document.getElementById(`${idType}_cam`).click();
    }
    if (e.target.name == "image") {
      document.getElementById(`${idType}_doc`).click();
    }
  };

  const retakeClick = (e) => {
    setUserfile({ preview: "", title: "" });
    setIsFileSelected(false);
    setSelectedFile("");
  };

  const handleFileChange = (event) => {
    setProgressBar(true);
    const HandleChangeTimeout = setTimeout(() => {
      setSelectedFile(event.target.files[0]);
      let imageFile = event.target.files[0];
      if (imageFile) {
        let extension = event.target.files[0].type;
        let imgsize = event.target.files[0].size;
        let allowedExtensions = ["image/jpeg", "image/png"];
        if (allowedExtensions.indexOf(extension) === -1) {
          setIsInValidfFile(true);
          setErrorMessage({
            txt: "The uploaded ID type should be an image in jpeg,png or jpg",
          });
          setProgressBar(false);
          return false;
        } else if (imgsize > 1024 * 1024 * 20) {
          setIsInValidfFile(true);
          setErrorMessage({ txt: "Please choose a file less than 20mb" });
          return false;
        } else {
          setSelectedFileType(event.target.name);
          setIsInValidfFile(false);
          setIsFileSelected(true);
        }

        setUserfile({
          preview: URL.createObjectURL(imageFile),
          title: imageFile.name,
        });
        if (imageFile) {
          let reader = new FileReader();
          reader.onload = _handleReaderLoaded.bind(this);
          reader.readAsDataURL(imageFile);
        }
      } else {
        setIsInValidfFile(true);
        setUserfile({ preview: "", title: "" });
      }
      setProgressBar(false);
    }, 2000);
  };
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBtnLoader(true);
    let uploadData = "";
    if (isFileSelected) {
      uploadData = {
        imageFile: base64Image.base64TextString,
        captureType: selectedFileType.split("_")[0],
        documentType: selectedFileType.split("_")[0],
      };
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        "user_docs_store"
      );
      setSelectedFile("");
      setBase64Image("");
      setSelectedFileType("");
      setShowSlide2(false);

      GTMDataLayer({
        clickEvent: "slideclick",
        question: "verify identity",
        answer: "",
      });

      // history.push(
      //   "/" +
      //     DynamicRouteNextPage +
      //     "?uuid=" +
      //     uuid +
      //     "&splitName=" +
      //     split_name
      // );
      setPreviousSurnameModelShow(true);
    } else {
      setBtnLoader(false);
      setIsInValidfFile(true);
      setErrorMessage({ txt: "Please upload a file" });
    }
  };

  //skip btn functionality

  const skipIdPage = async() => {
      const typeTwo = "id_upload";

      const page_name = "pcp/TCG_L_C_4";
      const skip_data = {
        type:"id_upload",
        bank_id:0
      }
      const response = await handleSkip(
          visitorParameters.visitor_parameters,
          visitorParameters.data,
          formParameters,
          queryString,
          skip_data,
          "skip-id-upload",
          split_name
      );
      setPreviousSurnameModelShow(true);

  };
  const handlePreviousDataSubmit = async (event, data = null) => {
    if (data != null) {
      const formData = JSON.parse(localStorage.getItem("formData")) || {};
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          formData[key] = data[key];
        }
      }
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'extra_form_data_previous_details',
        split_name,
        queryString,
        visitorParameters.data
      )
    }
    if (event == 'pageChange') {
      history.push(`/v4/thankyou?uuid=${visitorParameters.visitor_parameters.uuid}&splitName=${split_name}`);
    }
  }
  return (
    <>
      <div className="TCG_L_C">
        <GetVisitorsParams />
        <Loader loader={showLoader} contentText="" />

        {/* {showLoader && (
          <>
            <div className="loader_full_Pg">
              <div id="wifi-loader">
                <svg className="circle-outer" viewBox="0 0 86 86">
                  <circle className="back" cx="43" cy="43" r="40"></circle>
                  <circle className="front" cx="43" cy="43" r="40"></circle>
                  <circle className="new" cx="43" cy="43" r="40"></circle>
                </svg>
                <svg className="circle-middle" viewBox="0 0 60 60">
                  <circle className="back" cx="30" cy="30" r="27"></circle>
                  <circle className="front" cx="30" cy="30" r="27"></circle>
                </svg>
                <svg className="circle-inner" viewBox="0 0 34 34">
                  <circle className="back" cx="17" cy="17" r="14"></circle>
                  <circle className="front" cx="17" cy="17" r="14"></circle>
                </svg>
                <div
                  className="text"
                  data-text="Analysing Your Result..."
                ></div>
              </div>
            </div>
          </>
        )} */}
        {/* {!showLoader && ( */}
        <div className="wrapper w-100">
          <div className="main-container">
            <Header />
            <section className="form">
              <form className="main_form" id="step_1_form">
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="form-left col-lg-7 col-md-12 col-12">
                      <h1 className="head_text mb-3">
                        Now, let's verify your identity
                      </h1>
                      <p className="sub_head">
                        Your ID will be stored securely. We will use the
                        documents provided for identity verification and claim
                        progression.
                      </p>

                      <div
                        className="form-right top-up col-lg-4 col-md-12 col-12 d-block d-lg-none"
                        id="focusNext"
                      >
                        <div className="badge_right">
                          <div className="logo_design">
                            <LogoBasedOnDomain className="" bg="black" />
                            {/* <img src={tcgl_logo} alt="" /> */}
                          </div>
                          <div className="content_aware">
                            <p className="mb-0">Your Potential Claim Value:</p>
                            <h2 className="mb-0">
                              £1,100* <span>per vehicle!</span>
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="form-bg slide7 uploads-section mt-4 mb-4 animate__animated animate__fadeInUp">
                        {!showSlide2 ? (
                          <div className="uploadbtns ">
                            <Button
                              className="uploads w-100 id_upLoad fw-bold mb-3"
                              id="Driving"
                              name="idType"
                              onClick={handleNextUploadClick}
                              buttonText={
                                <>
                                  <span
                                    className="float-start"
                                    style={{ pointerEvents: "none" }}
                                  >
                                    <img
                                      height="25px"
                                      className="pe-2"
                                      src={car_insurance}
                                      alt=""
                                    />
                                    Driver's Licence{" "}
                                  </span>
                                  <span
                                    className="float-end"
                                    style={{ pointerEvents: "none" }}
                                  >
                                    <img
                                      height="35px"
                                      className="img_pop"
                                      src={uploadgif}
                                      alt=""
                                    />
                                  </span>
                                </>
                              }
                            />
                            <Button
                              className="uploads w-100 id_upLoad fw-bold mb-3"
                              id="passport"
                              name="idType"
                              onClick={handleNextUploadClick}
                              buttonText={
                                <>
                                  <span
                                    className="float-start"
                                    style={{ pointerEvents: "none" }}
                                  >
                                    <img
                                      height="25px"
                                      className="pe-2"
                                      src={plane}
                                      alt=""
                                    />
                                    Passport{" "}
                                  </span>
                                  <span
                                    className="float-end"
                                    style={{ pointerEvents: "none" }}
                                  >
                                    <img
                                      height="35px"
                                      className="img_pop"
                                      src={uploadgif}
                                      alt=""
                                    />
                                  </span>
                                </>
                              }
                            />

                            <p
                              className="upload-text"
                              style={{
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              When you upload a document, we will receive the
                              documents you have provided, which will be used to
                              progress and/or support your claim(s). Where
                              required, these documents will be shared with your
                              lender(s).
                            </p>
                          </div>
                        ) : (
                          <div>
                            <div id="uploarea">
                              <div
                                className=" mb-2 fs-5 backupload"
                                onClick={() => {
                                  handleBackbutton();
                                }}
                              >
                                {" "}
                                <i className="bi bi-arrow-left" /> Back
                              </div>
                              <div className="up-wrap p-1">
                                {isFileSelected ? (
                                  <div className="uploaded">
                                    <img
                                      className="img-fluid"
                                      style={{
                                        maxWidth: "100%",
                                        height: "190px",
                                      }}
                                      src={userfile.preview}
                                      alt={userfile.title}
                                    />
                                  </div>
                                ) : (
                                  <div className="uploadbox">
                                    <div>
                                      <div className="upload-text">
                                        {" "}
                                        Upload image{" "}
                                      </div>
                                    </div>

                                    {progressbar && (
                                      <div className="text-center imgloader ">
                                        <div
                                          className="progress"
                                          role="progressbar"
                                          aria-label="Animated striped example"
                                          aria-valuenow="75"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          <div
                                            className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                            style={{ width: "100%" }}
                                          ></div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              {isInValidfFile && (
                                <div className="error_msg">
                                  {errorMessage.txt}
                                </div>
                              )}
                              {!isFileSelected && (
                                <div className="row mt-3 upactionbtn">
                                  <div className="col-6">
                                    <div className="upl-btn  w-100">
                                      Take Photo{" "}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        capture="camera"
                                        id={`${idType}_cam`}
                                        name={`${idType}_cam`}
                                        onChange={handleFileChange}
                                        onClick={(e) => handleButtonClick(e)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 ps-0">
                                    <span className="upl-btn w-100">
                                      Upload image{" "}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        id={`${idType}_doc`}
                                        name={`${idType}_doc`}
                                        onChange={handleFileChange}
                                        onClick={(e) => handleButtonClick(e)}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                              {isFileSelected && (
                                <div className="row retake mt-3">
                                  <div className="col-12  ">
                                    <Button
                                      className="upl-btn w-100"
                                      onClick={(e) => retakeClick(e)}
                                      buttonText={
                                        <>
                                          <i className="bi bi-arrow-repeat" />
                                          &nbsp;&nbsp; Retake
                                        </>
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {isFileSelected && (
                                <>
                                  <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <ul>
                                      <li>
                                        <Button
                                          id="idupload"
                                          className="btn center-block next-but sctop w-100"
                                          onClick={(e) => handleSubmit(e)}
                                          buttonText={
                                            <>
                                              <span className="fw-bold">
                                                Submit
                                              </span>
                                            </>
                                          }
                                          gtm_question="verify identity"
                                          clickevent="slideclick"
                                          gtm_answer=""
                                          // gtm_trigger={true}
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className="text-center text-muted"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <p>
                                      When you upload a document, we will
                                      receive the documents you have provided,
                                      which will be used to progress and/or
                                      support your claim(s). Where required,
                                      these documents will be shared with your
                                      lender(s).
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-lg-3 mt-md-3 mt-sm-2 mt-1 text-center animate__animated animate__fadeInUp">
                        <Button
                          className="btn mt-3 center-block back-arrow sctop"
                          type="button"
                          id="skipLender"
                          onClick={() => skipIdPage()}
                          buttonText="Skip"
                          gtm_question="verify identity"
                          clickevent="slideclick"
                          gtm_answer=""
                          // gtm_trigger={true}
                        />
                      </div>
                    </div>
                    <div className="form-right top-up col-lg-4 col-md-12 col-12 d-none d-lg-block">
                      <div className="badge_right ">
                        <div className="logo_design">
                          <LogoBasedOnDomain className="" bg="black" />
                          {/* <img src={tcgl_logo} alt="" /> */}
                        </div>
                        <div className="content_aware">
                          <p className="mb-0 animate__animated animate__fadeInUp animate__delay-.5s">
                            Your Potential Claim Value:
                          </p>
                          <h2 className="mb-0 animate__animated animate__fadeInUp animate__delay-.5s">
                            £1,100* <span>per vehicle!</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
            <Footer superScriptQuote={true} />
          </div>
        </div>
      {previousSurnameModelShow &&
          <PreviousSurnameModel
            setPreviousSurnameModelShow={setPreviousSurnameModelShow}
            setPreviousAddressModelShow={setPreviousAddressModelShow}
            handlePreviousDataSubmit={handlePreviousDataSubmit}
          />}
        {previousAddressModelShow &&
          <PreviousAddressModel
            setPreviousAddressModelShow={setPreviousAddressModelShow}
            handlePreviousDataSubmit={handlePreviousDataSubmit}
          />}
      </div>
    </>
  );
};

export default IdUploadV5_C4;
