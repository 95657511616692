import React, { useState } from 'react';
import footerLogo from "../../../../assets/img/footer-logo.png";
import logoWhite from "../../../../assets/img/logo-white-v3.png";
import BrandBasedOnDomain from "../../../Includes/Layouts/Common/BrandBasedOnDomain";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import { getBrandName } from "../../../Includes/Layouts/Common/BrandBasedOnDomain";

const Footer = (props) => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const brandName = getBrandName();
  const modalClick = (clickValue) => {
    switch (clickValue) {

      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <>

      <div className="btm_sec">
        <footer className="_29ahV">
          {props.footerEnable && <div className="container-md">
            <div className="row justify-content-between g-3">
              <div className="col-12 col-md-3 col-sm-12 col-xl-3 text-center">
                <LogoBasedOnDomain className="img-fluid" width="836" height="142"/>
              </div>
              <div className="col-12 col-md-9 col-sm-12 col-xl-9">
                <p className="fot_cont m-0 text-lg-start text-center">
                  Copyright ©2024 {brandName}. All rights reserved
                </p>
                <p className="fot_cont m-0 text-lg-start text-center">
                  All rights reserved. {brandName} is a trading style of The Claims Guys Legal. The Claims Guys Legal Limited is authorised and regulated by the Solicitors Regulation Authority (SRA Number: 642517).
                </p>
                <p className="fot_cont mb-2 text-lg-start text-center">
                  *The FCA confirmed in their publication "Our work on motor finance – Final Finding"
                  that on a 4 year car finance agreement of £10,000, a Discretionary Commission Arrangement
                  typically resulted in consumers being overcharged £1,100 <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" className="text-white" target='_blank'>https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf</a> s. 2.15, Pg9.
                </p>
                <p className="fot_cont text-lg-start text-center mb-4">
                  <a href="https://theclaimsguyslegal.com/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp;
                  {/* |
                  &nbsp;<a href="https://theclaimsguyslegal.com/terms-of-use/" target="_blank">Terms & Conditions</a> */}
                </p>
              </div>

            </div>
          </div>}
          {props.superScriptQuote && <div className="container-md">
            <div className="row justify-content-between g-3">
              <div className="col-12 col-md-12 col-sm-12 col-xl-12 text-center">
                <p className="fot_cont mb-2 text-lg-start text-center">
                  *The FCA confirmed in their publication "Our work on motor finance – Final Finding"
                  that on a 4 year car finance agreement of £10,000, a Discretionary Commission Arrangement
                  typically resulted in consumers being overcharged £1,100 <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" className="text-white" target='_blank'>https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf</a> s. 2.15, Pg9.
                </p>
              </div>

            </div>
          </div>}
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center fot_cont pt-3 fw-bold br-top">
                Copyright ©2024 {brandName}.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;